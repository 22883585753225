import { IconFileTypePdf, IconX } from "@tabler/icons-react";
import React, { useState } from "react";
import axios from "axios";
import ViewExtractedFile from "./ViewExtractedFile";
import UploadScreen from "./UploadScreen";
import ViewExtractedUrl from "./ViewExtractedUrl";
import WarningPopup from "../../components/WarningPopup";

const FileUpload: React.FC = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [data, setData] = useState("");
  const [url, setUrl] = useState("");
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [isSelectedFile, setIsSelectedFile] = useState(false);
  const [isSelectedUrl, setIsSelectedUrl] = useState(false);
  const [downloadFileName, setDownloadFileName] = useState("");
  const [viewUrlExtract, setViewUrlExtract] = useState(false);
  const [isOpen, setIsOpen] = useState(false)
  const [errorResponseMessage, setErrorResponseMessage]= useState('')
  const handleFileUpload = async (file: File) => {
    if (file.type !== "application/pdf") {
      setErrorMessage("Invalid file type. Please upload a PDF file.");
      return;
    }
    setPdfFile(file);
    setErrorMessage(null);
    setFileName(file.name);
    const fileData = new FormData();
    fileData.append("file", file);
    console.log("fileData", fileData);
    const payload = {
      file: file,
      url: "string",
    };
    const response = axios
      .post(`${apiUrl}/app/extract_risks/`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data);
        console.log(response.data.risks);
        setData(response.data.risks);
        setDownloadFileName(response.data.file_download_link);
      })
      .catch(function (error) {
        console.log(error.response?.data.detail);
      });
    console.log("response", response);
  };

  const handleCancel = (): void => {
    setFileName(null);
    setData("");
  };

  const handleBtnFile = () => {
    setIsSelectedFile(true);
    setIsSelectedUrl(false);
    setUrl("");
    setData("");
    setViewUrlExtract(false)
  };

  const handleBtnUrl = () => {
    setIsSelectedUrl(true);
    setIsSelectedFile(false);
  };

  const handleUrlChange = (event: any) => {
    setUrl(event.target.value);
  };

  const handleUploadUrl = async () => {
    setViewUrlExtract(true);
    const payload = {
      file: "",
      url: url,
    };
    axios
      .post(`${apiUrl}/app/extract_risks`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response);
        setData(response.data.risks);
        setDownloadFileName(response.data.file_download_link);
      })
      .catch(function (error) {
        console.log(error.response?.data.detail);
        setErrorResponseMessage(error.response?.data.detail)
        setData('');
        setFileName(null);
        setViewUrlExtract(false);
        setIsOpen(true)
      });
  };

  const handleCancelUrl = () => {
    setUrl("");
    setIsSelectedUrl(false);
    setData("");
    setViewUrlExtract(false);
  };

  const onClose = () =>{
    setIsOpen(false)
    setErrorResponseMessage('')
  }

  return (
    <div>
      <div className="flex flex-col items-center w-full">
        <span className="text-4xl font-bold text-blue-700">
          Broadridge AI Analysis Risks Extraction
        </span>
        {!fileName && (
          <>
            <span className="text-lg  mt-12 ">
              Want to upload URL or File ?{" "}
            </span>
            <div className="flex flex-col items-center mt-4">
              <div className="flex space-x-4">
                <button
                  type="button"
                  onClick={handleBtnUrl}
                  className="px-4 py-2 w-36 bg-blue-700 text-white rounded-lg hover:bg-blue-500 flex items-center justify-center"
                >
                  URL
                </button>
                <button
                  type="button"
                  onClick={handleBtnFile}
                  className="px-4 py-2 w-36 bg-blue-700 text-white rounded-lg hover:bg-blue-500 flex items-center justify-center"
                >
                  File
                </button>
              </div>
            </div>
          </>
        )}
        {isSelectedUrl && (
          <>
            <span className="text-md font-medium mt-6 mb-1">Upload a URL</span>
            <div className="relative flex items-stretch p-10 w-full max-w-2xl bg-blue-50">
              <IconX
                stroke={1}
                className="text-grey-300 h-5 w-5 absolute top-2 right-2 cursor-pointer"
                onClick={handleCancelUrl}
              />
              <input
                type="text"
                value={url}
                onChange={handleUrlChange}
                placeholder="Enter URL"
                className="border-2 px-2 py-2 w-full"
              />
              <button
                type="button"
                onClick={handleUploadUrl}
                disabled={!url}
                className={`ml-1 px-4 py-2 w-36 bg-blue-500 text-white rounded-lg flex items-center justify-center ${
                  !url ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-600"
                }`}
              >
                Upload
              </button>
            </div>
          </>
        )}
        {isSelectedFile && (
          <UploadScreen
            handleFileUpload={handleFileUpload}
            fileName={fileName}
            errorMessage={errorMessage}
          />
        )}
      </div>

      {fileName && (
        <div className="flex  items-center justify-center h-full w-full p-4">
          <div className="relative flex items-center justify-between p-2  w-full max-w-2xl h-24 border rounded-lg bg-sky-50">
            <div className="flex items-center">
              <IconFileTypePdf stroke={1} className="h-10 w-10" />
              <div className="ml-4 flex flex-col">
                <p className="text-gray-700">{fileName}</p>
                {/* <h2 className="text-gray-500 text-sm">15MB</h2> */}
              </div>
            </div>
            <IconX
              stroke={1}
              className="text-gray-600 h-4 w-4 absolute top-2 right-2 cursor-pointer"
              onClick={handleCancel}
            />
          </div>
        </div>
      )}

      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-6 relative mt-12">
          {pdfFile && fileName && (
            <>
              <h1 className="text-lg font-semibold text-blue-700">
                Uploaded File
              </h1>
              <iframe
                src={URL.createObjectURL(pdfFile)}
                title="Pdf"
                className="w-full h-96"
              />
            </>
          )}
        </div>
        <div className="flex col-span-6 items-center justify-center ">
          {fileName && (
            <ViewExtractedFile
              data={data}
              downloadFileName={downloadFileName}
            />
          )}
        </div>
      </div>

      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-6 relative mt-12">
          {viewUrlExtract && (
            <>
              <h1 className="text-lg font-semibold text-blue-700">
                Uploaded File
              </h1>
              <iframe src={url} title="Pdf" className="w-full h-96" />
            </>
          )}
        </div>
        <div className="flex col-span-6 items-center justify-center ">
          {viewUrlExtract && (
            <ViewExtractedUrl data={data} downloadFileName={downloadFileName} />
          )}
        </div>
      </div>
      <div></div>
      <WarningPopup
          isOpen={isOpen}
          onClose={onClose}
          title=" "
          showCancelButton={true}
          size="w-2/5"
        >
          <div className="p-3 text-center">
            <span className=" text-lg">{errorResponseMessage}</span>
          </div>
        </WarningPopup>
    </div>
  );
};

export default FileUpload;
